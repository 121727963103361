import "./App.css";
import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom"; // Import both from the same module
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import NameForProfile from "./pages/NameForProfile";
import LocationForProfile from "./pages/LocationForProfile";
import InterestsForProfile from "./pages/InterestsForProfile";
import GoalForProfile from "./pages/GoalForProfile";
import Home from "./pages/Home";

const myRoutes = createBrowserRouter([
    {
        path: "/",
        element: <Login />,
    },
    {
        path: "/home",
        element: <Home />,
    },
    {
        path: "/signup",
        element: <SignUp />,
    },
    {
        path: "/nameforprofile",
        element: <NameForProfile />,
    },
    {
        path: "/locationforprofile",
        element: <LocationForProfile />,
    },
    {
        path: "/interestsforprofile",
        element: <InterestsForProfile />,
    },
    {
        path: "/goalforprofile",
        element: <GoalForProfile />,
    },
]);

function App() {
    return (
        <div className="App">
            <RouterProvider router={myRoutes} />
        </div>
    );
}

export default App;
