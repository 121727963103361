import React from "react";
import homepage from "../images/homepage.svg";
import home from "../images/home.svg";
import flaticons from "../images/flaticons.svg";
import icon from "../images/icon-nav.svg";
import map from "../images/map.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const Home = () => {
    return (
        <div
            className="container-fluid"
            style={{ backgroundColor: "#f3ede1", minHeight: "100vh" }}
        >
            <div className="container-fluid">
                <div
                    className="row"
                    id="nav"
                    style={{ backgroundColor: "#f0e0e3" }}
                >
                    <div className="col-1">
                        <img src={homepage} alt="logo" />
                    </div>
                    <div className="col-4"></div>
                    <div className="col-3 text-end" id="icons">
                        <ul className="nav justify-content-end">
                            <li className="nav-item">
                                <a
                                    href="#"
                                    className="nav-link active"
                                    aria-current="page"
                                >
                                    <img src={home} alt="home icon" />
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <img
                                        src={flaticons}
                                        alt="communication icon"
                                    />
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <img src={icon} alt="user icon" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row" id="search-area">
                    {" "}
                    {/* Changed ID to be unique */}
                    <div className="col col-5">
                        <form className="d-flex" role="search">
                            <div className="input-group">
                                <span className="input-group-text">
                                    <FontAwesomeIcon icon={faSearch} />
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search for Users"
                                    aria-label="Search for Users"
                                />
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Location"
                                    aria-label="Location"
                                />
                                <span className="input-group-text">
                                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                                </span>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row" id="main-content">
                    {" "}
                    {/* Changed ID to be unique */}
                    <div className="col">
                        <div className="card" style={{ width: "18rem" }}></div>
                    </div>
                    <div className="col-8">
                        <div className="map" img={map}></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
