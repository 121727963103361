import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../images/shell-logo.svg";
import backendUrl from "../api";

const GoalForProfile = () => {
    // Initializing state variables for form inputs
    const [title, setTitle] = useState("");
    const [category, setCategory] = useState("");
    const [location, setLocation] = useState("");
    const [DateOnly, setDateOnly] = useState("");
    const [endDate, setEndDate] = useState("");
    const [goalIntention, setGoalIntention] = useState("");
    const navigate = useNavigate();

    // Load data from local storage if available
    useEffect(() => {
        const data = localStorage.getItem("goalFormData");
        if (data) {
            const formData = JSON.parse(data);
            setTitle(formData.title);
            setCategory(formData.category);
            setLocation(formData.location);
            setDateOnly(formData.DateOnly);
            setEndDate(formData.endDate);
            setGoalIntention(formData.goalIntention);
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = {
            title,
            category,
            location,
            DateOnly,
            endDate,
            goalIntention,
        };

        //const timeStamp = new Date().getTime();
        // localStorage.setItem(
        //     `goalFormData_${timeStamp}`,
        //     JSON.stringify(formData)
        // );

        // console.log("Form Submitted", formData);
        try {
            const response = await fetch(
                "http://localhost:3001/goalforprofile",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(formData),
                }
            );

            const result = await response.json(); // Decode JSON response from the server

            if (!response.ok) {
                throw new Error(result.message || "Failed to submit goal");
            }

            console.log("Goal submitted successfully:", result);
            localStorage.removeItem("goalFormData");
            navigate("/"); // Navigate to the next step or confirmation page
        } catch (error) {
            console.error("Error submitting goal:", error);
        }
    };

    return (
        <div
            className="container-fluid"
            style={{ backgroundColor: "#f0e0e3", minHeight: "100vh" }}
        >
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1
                        id="logo-name"
                        style={{ fontFamily: "Roca One", fontStyle: "italic" }}
                    >
                        zenfen
                    </h1>
                </div>
                <div className="d-flex justify-content-center">
                    <img src={logo} alt="logo" width={150} />
                </div>
                <div className="d-flex justify-content-center">
                    <h6 style={{ fontFamily: "Roca One" }}>
                        Setup a goal to start
                    </h6>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="gap-2 col-4 mx-auto">
                    <label htmlFor="CityInput" className="form-label">
                        Title
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="CityInput"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>
                <div className="gap-2 col-4 mx-auto">
                    <label htmlFor="CategoryInput" className="form-label">
                        Category
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="CategoryInput"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                    />
                </div>
                <div className="gap-2 col-4 mx-auto">
                    <label htmlFor="LocationInput" className="form-label">
                        Location
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="LocationInput"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                    />
                </div>
                <div className="gap-2 col-4 mx-auto">
                    <label htmlFor="DateOnlyInput" className="form-label">
                        Start Date
                    </label>
                    <input
                        type="date"
                        className="form-control"
                        id="DateOnlyInput"
                        placeholder="mm/dd/yyyy"
                        value={DateOnly}
                        onChange={(e) => setDateOnly(e.target.value)}
                    />
                </div>
                <div className="gap-2 col-4 mx-auto">
                    <label htmlFor="EndDateInput" className="form-label">
                        End Date
                    </label>
                    <input
                        type="date"
                        className="form-control"
                        id="EndDateInput"
                        placeholder="mm/dd/yyyy"
                        min="2023-01-01"
                        max="2030-12-31"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </div>
                <div className="gap-2 col-4 mx-auto">
                    <label htmlFor="GoalIntentionInput" className="form-label">
                        Goal Intention
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="GoalIntentionInput"
                        value={goalIntention}
                        onChange={(e) => setGoalIntention(e.target.value)}
                    />
                </div>
                <div className="gap-2 col-4 mx-auto">
                    <br />
                </div>
                <div className="d-grid gap-2 col-4 mx-auto">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        style={{
                            backgroundColor: "#809ec8",
                            borderColor: "#809ec8",
                        }}
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
};

export default GoalForProfile;
