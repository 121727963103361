import React, { useState, useEffect } from "react";
import logo from "../images/shell-logo.svg";
import { Link, useNavigate } from "react-router-dom";
import backendUrl from "../api";

const LocationForProfile = () => {
    const [city, setCity] = useState(localStorage.getItem("city") || "");
    const [state, setState] = useState(localStorage.getItem("state") || "");
    const [country, setCountry] = useState(
        localStorage.getItem("country") || ""
    );
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.setItem("city", city);
        localStorage.setItem("state", state);
        localStorage.setItem("country", country);
    }, [city, state, country]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Location data saved:", { city, state, country });

        let url = backendUrl;

        try {
            const response = await fetch(url + "/locationforprofile", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ city, state, country }),
            });

            const result = await response.json(); // Assuming server responds with JSON

            if (response.ok) {
                console.log("Location data submitted successfully:", result);
                navigate("/interestsforprofile"); // Navigate to the next step or confirmation page
            } else {
                throw new Error(
                    result.message || "An error occurred while submitting data."
                );
            }
        } catch (error) {
            console.error("Error submitting location data:", error);
        }
    };

    return (
        <div
            className="container-fluid"
            style={{ backgroundColor: "#f0e0e3", minHeight: "100vh" }}
        >
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1
                        id="logo-name"
                        style={{ fontFamily: "Roca One", fontStyle: "italic" }}
                    >
                        zenfen
                    </h1>
                </div>
                <div className="d-flex justify-content-center">
                    <img src={logo} alt="logo" width={150} />
                </div>
                <div className="d-flex justify-content-center">
                    <h6 style={{ fontFamily: "Roca One" }}>
                        Tell us your location so we can show you local zenfems
                    </h6>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="gap-2 col-4 mx-auto">
                    <label htmlFor="CityInput" className="form-label">
                        City
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="CityInput"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                    />
                </div>
                <div className="gap-2 col-4 mx-auto">
                    <label htmlFor="StateInput" className="form-label">
                        State
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="StateInput"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                    />
                </div>
                <div className="gap-2 col-4 mx-auto">
                    <label htmlFor="CountryInput" className="form-label">
                        Country
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="CountryInput"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                    />
                </div>
                <div className="gap-2 col-4 mx-auto">
                    <br />
                </div>
                <div className="d-grid gap-2 col-4 mx-auto">
                    <Link
                        to="/interestsforprofile"
                        type="submit"
                        className="btn btn-primary"
                        style={{
                            backgroundColor: "#809ec8",
                            borderColor: "#809ec8",
                        }}
                    >
                        Continue
                    </Link>
                </div>
                <div className="gap-2 col-4 mx-auto">
                    <br />
                </div>
                <div className=" gap-2 col-4 mx-auto">
                    <label htmlFor="progress" className="form-label">
                        Step 2 out 4{" "}
                    </label>
                    {/* <input
                        type="range"
                        className="form-range"
                        min="0"
                        max="4"
                        step="1"
                        value="1"
                    /> */}
                    <div
                        className="progress"
                        role="progressbar"
                        aria-label="Basic example"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    >
                        <div
                            className="progress-bar"
                            style={{ width: "50%", backgroundColor: "#809ec8" }}
                        ></div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default LocationForProfile;
