import React from "react";
import { Link, useNavigate } from "react-router-dom";

import logo from "../images/shell-logo.svg";
import signup from "../images/signup.png";
import "./SignUp.css";
import { useState } from "react";
import backendUrl from "../api";

const SignUp = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const signUpFormSubmitted = async (event) => {
        event.preventDefault();
        if (!email || !password) {
            setError("All fields are required");
            return; // Stop the function if fields are missing
        }

        let url = backendUrl;

        // Perform the fetch request to the server
        try {
            const response = await fetch(url + "/signup", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email,
                    password,
                }),
            });

            const data = await response.json(); // Decode JSON response

            if (!response.ok) {
                throw new Error(data.message || "Failed to sign up");
            }

            console.log("User signed up successfully: ", data);

            setEmail("");
            setPassword("");
            setError(null);

            // Navigate to another route upon successful signup, e.g., login or profile page
            navigate("/login"); // Change as per your route settings
        } catch (error) {
            setError(error.message);
            console.error("Sign up error: ", error);
        }
    };

    return (
        <div
            className="container-fluid"
            style={{ backgroundColor: "#f0e0e3", minHeight: "100vh" }}
        >
            <div className="row">
                <div className="col">
                    <div className="d-flex justify-content-center">
                        <h1
                            id="signup-name"
                            style={{
                                fontFamily: "Roca One",
                                fontStyle: "italic",
                            }}
                        >
                            zenfen
                        </h1>
                    </div>
                    <div className="d-flex justify-content-center">
                        <img src={logo} alt="logo" width={150} />
                    </div>
                    <div className="d-grid gap-2 col-6 mx-auto">
                        <h4>Create an account</h4>
                    </div>

                    <form onSubmit={signUpFormSubmitted}>
                        <div className="gap-2 col-6 mx-auto" id="signup-email">
                            <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                            >
                                Email address
                            </label>
                            <input
                                type="email"
                                className="form-control"
                                id="exampleInputEmail1"
                                value={email}
                                onChange={(event) =>
                                    setEmail(event.target.value)
                                }
                            />
                        </div>
                        <div
                            className="gap-2 col-6 mx-auto"
                            id="signup-password"
                        >
                            <label
                                htmlFor="exampleInputPassword1"
                                className="form-label"
                            >
                                Password
                            </label>
                            <input
                                type="password"
                                className="form-control"
                                id="exampleInputPassword1"
                                value={password}
                                onChange={(event) =>
                                    setPassword(event.target.value)
                                }
                            />
                        </div>

                        <div className="d-grid gap-2 col-6 mx-auto">
                            <Link
                                to="/nameforprofile"
                                className="btn btn-primary"
                                type="submit" // Changed to submit
                                style={{
                                    backgroundColor: "#809ec8",
                                    borderColor: "#809ec8",
                                }}
                            >
                                Sign Up
                            </Link>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "space-between",
                                }}
                            >
                                <div
                                    style={{
                                        width: "45%",
                                        borderTop: "1px solid white",
                                        marginTop: 13,
                                    }}
                                ></div>
                                <div
                                    style={{ paddingLeft: 5, paddingRight: 5 }}
                                >
                                    {" "}
                                    or{" "}
                                </div>
                                <div
                                    style={{
                                        width: "45%",
                                        borderTop: "1px solid white",
                                        marginTop: 13,
                                    }}
                                ></div>
                            </div>
                            <button
                                className="btn btn-primary"
                                type="button"
                                style={{
                                    backgroundColor: "#809ec8",
                                    borderColor: "#809ec8",
                                }}
                            >
                                Sign Up with Google
                            </button>
                        </div>

                        <div className="d-grid gap-2 col-6 mx-auto">
                            <h6>
                                Already have an account?{" "}
                                <Link
                                    to="/login"
                                    className="text-decoration-underline"
                                >
                                    Sign In!
                                </Link>{" "}
                                {/* Changed to Link component */}
                            </h6>
                        </div>
                        <p style={{ color: "red" }}>{error}</p>
                    </form>
                </div>
                <div className="col">
                    <img src={signup} className="img-fluid" alt="Login" />
                </div>
            </div>
        </div>
    );
};

export default SignUp;
