import React, { useState, useEffect } from "react";

function diff_years(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60 * 24;
    return Math.abs(Math.round(diff / 365.25));
}

const BirthDateSelector = ({
    setDayOfBirth,
    setMonthOfBirth,
    setYearOfBirth,
    setAge,
}) => {
    const [day, setDay] = useState("");
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const [daysInMonth, setDaysInMonth] = useState([]);

    const months = [
        { value: 1, name: "Jan" },
        { value: 2, name: "Feb" },
        { value: 3, name: "Mar" },
        { value: 4, name: "Apr" },
        { value: 5, name: "May" },
        { value: 6, name: "Jun" },
        { value: 7, name: "Jul" },
        { value: 8, name: "Aug" },
        { value: 9, name: "Sep" },
        { value: 10, name: "Oct" },
        { value: 11, name: "Nov" },
        { value: 12, name: "Dec" },
    ];

    const years = Array.from(
        new Array(100),
        (val, index) => new Date().getFullYear() - index
    );

    // Effect to update parent state
    useEffect(() => {
        setDayOfBirth(day);
        setMonthOfBirth(month);
        setYearOfBirth(year);
    }, [day, month, year, setDayOfBirth, setMonthOfBirth, setYearOfBirth]);

    // Effect to calculate days in a month when month or year changes
    useEffect(() => {
        if (!month || !year) {
            return;
        }
        const date2 = new Date(`${month}/${day}/${year}`);
        console.log(date2);
        const days = new Date(parseInt(year), parseInt(month), 0).getDate();
        setAge(diff_years(new Date(), date2));
        setDaysInMonth(Array.from({ length: days }, (_, i) => i + 1));
    }, [month, year]);

    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-2" style={{ paddingLeft: 0 }}>
                    <label htmlFor="yearOfBirth" className="form-label">
                        Year
                    </label>
                    <select
                        className="form-select"
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                        id="yearOfBirth"
                    >
                        <option value="">Select Year</option>
                        {years.map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-1 ">
                    <label htmlFor="monthOfBirth" className="form-label">
                        Month
                    </label>
                    <select
                        className="form-select"
                        value={month}
                        onChange={(e) => setMonth(e.target.value)}
                        id="monthOfBirth"
                    >
                        <option value="">Select Month</option>
                        {months.map((m) => (
                            <option key={m.value} value={m.value}>
                                {m.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-1" style={{ paddingRight: 0 }}>
                    <label htmlFor="DayOfBirth" className="form-label">
                        Day
                    </label>
                    <select
                        className="form-select"
                        value={day}
                        onChange={(e) => setDay(e.target.value)}
                        id="DayOfBirth"
                    >
                        <option value="">Select Day</option>
                        {daysInMonth.map((day) => (
                            <option key={day} value={day}>
                                {day}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
};

export default BirthDateSelector;
