import React from "react";
import login from "../images/login.png";
import logo from "../images/shell-logo.svg";
import "./Login.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import backendUrl from "../api";

const Login = () => {
    const [useremail, setUseremail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const loginFormSubmitted = async (event) => {
        event.preventDefault();
        console.log("login form submitted");
        console.log(useremail, password);

        let url = backendUrl();

        const res = await fetch(url + "/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                useremail,
                password,
            }),
        });

        const data = await res.json();
        if (data.error) {
            setError(data.error);
        } else {
            window.localStorage.setItem("loggedIn", "yes");
            window.localStorage.setItem("useremail", useremail);
            navigate("/");
        }

        //.localStorage.setItem("loggedIn", "yes");
        //redirect
        //navigate("/newPost");
    };
    return (
        <div className="container-fluid" style={{ backgroundColor: "#f0e0e3" }}>
            <div className="row">
                <div className="col">
                    <div className="d-flex justify-content-center">
                        <h1
                            id="logo-name"
                            style={{
                                fontFamily: "Roca One",
                                fontStyle: "italic",
                            }}
                        >
                            zenfen
                        </h1>
                    </div>
                    <div className="d-flex justify-content-center">
                        <img src={logo} alt="logo" width={150} />
                    </div>
                    <div className="d-grid gap-2 col-6 mx-auto">
                        <h4>Login</h4>
                    </div>

                    <form onSubmit={loginFormSubmitted}>
                        <div className=" gap-2 col-6 mx-auto " id="login-email">
                            <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                            >
                                Email address
                            </label>
                            <input
                                type="email"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={useremail}
                                onChange={(event) =>
                                    setUseremail(event.target.value)
                                }
                            />
                        </div>
                        <div
                            className=" gap-2 col-6 mx-auto"
                            id="login-password"
                        >
                            <label
                                htmlFor="exampleInputPassword1"
                                className="form-label"
                            >
                                Password
                            </label>
                            <input
                                type="password"
                                className="form-control"
                                id="exampleInputPassword1"
                                value={password}
                                onChange={(event) =>
                                    setPassword(event.target.value)
                                }
                            />
                        </div>

                        <div className="d-grid gap-2 col-6 mx-auto">
                            <button
                                className="btn btn-primary"
                                type="submit"
                                style={{
                                    backgroundColor: "#809ec8",
                                    borderColor: "#809ec8",
                                }}
                            >
                                Login
                            </button>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "space-between",
                                }}
                            >
                                <div
                                    style={{
                                        width: "45%",
                                        borderTop: "1px solid black",
                                        marginTop: 13,
                                    }}
                                ></div>
                                <div
                                    style={{ paddingLeft: 5, paddingRight: 5 }}
                                >
                                    {" "}
                                    or{" "}
                                </div>
                                <div
                                    style={{
                                        width: "45%",
                                        borderTop: "1px solid black",
                                        marginTop: 13,
                                    }}
                                ></div>
                            </div>
                            <button
                                className="btn btn-primary"
                                type="button"
                                style={{
                                    backgroundColor: "#809ec8",
                                    borderColor: "#809ec8",
                                }}
                            >
                                Login with Google
                            </button>
                        </div>

                        <div className="d-grid gap-2 col-6 mx-auto">
                            <h6>
                                Don't have an account?{" "}
                                <Link
                                    to="/signup"
                                    className="text-decoration-underline"
                                >
                                    Sign Up
                                </Link>
                            </h6>
                        </div>
                        <p style={{ color: "red" }}>{error}</p>
                    </form>
                </div>
                <div className="col">
                    <img src={login} className="img-fluid" alt="Login" />
                </div>
            </div>
        </div>
    );
};

export default Login;
