import React, { useState } from "react";
import logo from "../images/shell-logo.svg";
import BirthDateSelector from "../component/BirthDateSelector"; // Ensure path is correct
import { Link, useNavigate } from "react-router-dom";
import backendUrl from "../api";

const NameForProfile = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [monthOfBirth, setMonthOfBirth] = useState("");
    const [yearOfBirth, setYearOfBirth] = useState("");
    const [age, setAge] = useState("");
    const [isAdult, setIsAdult] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        const birthDate = new Date(
            parseInt(yearOfBirth),
            parseInt(monthOfBirth) - 1,
            parseInt(dateOfBirth)
        );
        const today = new Date();
        let calculatedAge = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            calculatedAge--;
        }

        setAge(calculatedAge);
        setIsAdult(calculatedAge >= 18);

        const userData = {
            firstName,
            lastName,
            dateOfBirth,
            monthOfBirth,
            yearOfBirth,
            age: calculatedAge,
            isAdult: calculatedAge >= 18,
        };

        //localStorage.setItem("userData", JSON.stringify(userData));
        //console.log("User data saved to local storage:", userData);
        let url = backendUrl;
        try {
            const response = await fetch(url + "/nameforprofile", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(userData),
            });

            const result = await response.json(); // Assuming server responds with JSON

            if (response.ok) {
                console.log("User data submitted successfully:", result);
                navigate("/");
            } else {
                throw new Error(
                    result.message || "An error occurred while submitting data."
                );
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    return (
        <div
            className="container-fluid"
            style={{ backgroundColor: "#f0e0e3", minHeight: "100vh" }}
        >
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1
                        id="logo-name"
                        style={{ fontFamily: "Roca One", fontStyle: "italic" }}
                    >
                        zenfen
                    </h1>
                </div>
                <div className="d-flex justify-content-center">
                    <img src={logo} alt="logo" width={150} />
                </div>
                <div className="d-flex justify-content-center">
                    <h4 style={{ fontFamily: "Roca One" }}>Enter your name</h4>
                </div>
                <div className="d-flex justify-content-center">
                    <h6 style={{ fontFamily: "Roca One", fontSize: "15px" }}>
                        it will be shown on your public profile
                    </h6>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="gap-2 col-4 mx-auto">
                    <label htmlFor="FirstNameInput" className="form-label">
                        First Name
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="FirstNameInput"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                </div>
                <div className="gap-2 col-4 mx-auto">
                    <label htmlFor="LastNameInput" className="form-label">
                        Last Name
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="LastNameInput"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                </div>
                <BirthDateSelector
                    setDayOfBirth={setDateOfBirth}
                    setMonthOfBirth={setMonthOfBirth}
                    setYearOfBirth={setYearOfBirth}
                    setAge={setAge}
                />
                <div className="gap-2 col-4 mx-auto">
                    <label htmlFor="AgeInput" className="form-label">
                        Age
                    </label>
                    <input
                        type="number"
                        className="form-control"
                        id="AgeInput"
                        value={age}
                    />
                </div>
                <div className="mb-3 form-check d-flex justify-content-center">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                        checked={isAdult}
                    />
                    <label className="form-check-label" htmlFor="exampleCheck1">
                        I certify that I am 18 years of age or older
                    </label>
                </div>
                <div className="d-grid gap-2 col-4 mx-auto">
                    <Link
                        to="/locationforprofile"
                        className="btn btn-primary"
                        style={{
                            backgroundColor: "#809ec8",
                            borderColor: "#809ec8",
                        }}
                    >
                        Continue
                    </Link>
                </div>
                <div className=" gap-2 col-4 mx-auto">
                    <label htmlFor="progress" className="form-label">
                        Step 1 out 4{" "}
                    </label>
                    {/* <input
                        type="range"
                        className="form-range"
                        min="0"
                        max="4"
                        step="1"
                        value="1"
                    /> */}
                    <div
                        className="progress"
                        role="progressbar"
                        aria-label="Basic example"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    >
                        <div
                            className="progress-bar"
                            style={{ width: "25%", backgroundColor: "#809ec8" }}
                        ></div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default NameForProfile;
