import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import logo from "../images/shell-logo.svg";
import backendUrl from "../api";

const InterestsForProfile = () => {
    const [interests, setInterests] = useState("");
    const [curiousAbout, setCuriousAbout] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log("Submitting Interests:", { interests, curiousAbout });

        // Construct the payload to send to the server
        const payload = {
            interests,
            curiousAbout,
        };
        let url = backendUrl;
        // Make the POST request to the server
        try {
            const response = await fetch(url + "/interestsforprofile", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });

            const result = await response.json(); // Decode the JSON response from the server

            if (!response.ok) {
                throw new Error(
                    result.message ||
                        "Failed to update interests and curiosities"
                );
            }

            console.log("Interests updated successfully:", result);
            navigate("/goalforprofile"); // Navigate to the next part of the profile setup
        } catch (error) {
            console.error("Error submitting interests:", error);
        }
    };

    return (
        <div
            className="container-fluid"
            style={{ backgroundColor: "#f0e0e3", minHeight: "100vh" }}
        >
            <div className="col">
                <div className="d-flex justify-content-center">
                    <h1
                        id="logo-name"
                        style={{ fontFamily: "Roca One", fontStyle: "italic" }}
                    >
                        zenfen
                    </h1>
                </div>
                <div className="d-flex justify-content-center">
                    <img src={logo} alt="logo" width={150} />
                </div>
                <div className="d-flex justify-content-center">
                    <h6 style={{ fontFamily: "Roca One" }}>
                        Tell us your interests and what you are curious about
                    </h6>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="gap-2 col-4 mx-auto">
                    <label htmlFor="InterestsInput" className="form-label">
                        Interests
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="InterestsInput"
                        value={interests}
                        onChange={(e) => setInterests(e.target.value)}
                    />
                </div>
                <div className="gap-2 col-4 mx-auto">
                    <label htmlFor="CuriousInput" className="form-label">
                        Curious About
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="CuriousInput"
                        value={curiousAbout}
                        onChange={(e) => setCuriousAbout(e.target.value)}
                    />
                </div>

                <div className="gap-2 col-4 mx-auto">
                    <br />
                </div>
                <div className="d-grid gap-2 col-4 mx-auto">
                    <Link
                        to="/goalforprofile"
                        type="submit"
                        className="btn btn-primary"
                        style={{
                            backgroundColor: "#809ec8",
                            borderColor: "#809ec8",
                        }}
                    >
                        Continue
                    </Link>
                </div>
                <div className="gap-2 col-4 mx-auto">
                    <br />
                </div>
                <div className="gap-2 col-4 mx-auto">
                    <label htmlFor="progress" className="form-label">
                        Step 3 out 4
                    </label>
                    <div
                        className="progress"
                        role="progressbar"
                        aria-label="Basic example"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    >
                        <div
                            className="progress-bar"
                            style={{ width: "75%", backgroundColor: "#809ec8" }}
                        ></div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default InterestsForProfile;
